//footer
.footer-v1 {
	background: var(--footer-layout-colour);
	//background: var(--accent-color);
	color: var(--footer-layout-font-colour);
	* {
		color: var(--footer-layout-font-colour);
	}
}

.footer-color{
	color: var(--footer-layout-font-colour);
}