.header-2 {
    @media (min-width: 992px) {
        .header-content {
            padding: 0px 200px;
            color: #000;
        }
    }

    @media (min-width: 576px) and (max-width: 767px) {
        .header-content {
            padding: 0px;
            color: #000;
        }
    }
    .mat-icon-lg {
        color: #fff;
    }
    .cart-items-count {
        position: absolute;
        top: -3px;
        left: 26px;
        background: var(--accent-color);
        height: 18px;
        width: 18px;
        line-height: 18px;
        border-radius: 50%;
        font-size: 11px;
        color: #fff;
      }

    .row-2 {
        border-top: 1px solid var(--border-color);
        .menu {
            color: #fff;
        }
    }
}
