.header-4 {
    background-color: var(--primary-color);
    color: #fff;
    padding: 10px 200px;
}
.stage-1 {
    background-color: #fff;
    color: #000;
    padding: 15px 200px;

    .appSearch {
        width: 500px;
    }
    .appSearch:hover {
        width: 500px;
        border: 1px solid #000;
    }

    .mat-icon-lg{
        color: rgb(0, 0, 0);
    }

   
}

.stage-2 {
    padding: 15px 150px;
    background-color: #fff;
    .content {
        background-color: #f3f3f3;
        color: #000;
        padding: 0px;
    }
}


.search2{
    width: 500px;
}
.mat-icon-lg{
    color: #fff;
}

